import { useEffect, useState} from "react";
import React from 'react';
import "./App.css";
import _ from 'lodash'
import {motion} from 'framer-motion';
import {FaShoppingBasket} from 'react-icons/fa';
import BasketView from'./Basket';
import {Routes, Route, Link} from 'react-router-dom';
import { getBasketValue, setBasketValue } from './BasketValue.js';
import { useLocation } from 'react-router-dom';

let products2 = [];

await fetch('https://naturianki.pl/api2/')
      .then(res => res.json())
      .then(data => {
        products2 = data['data'];
      });

const transformData = (api2Data) => {
  // Definiujemy strukturę nagłówków zgodnie z API1
  const header = ["nazwa", "kategoria", "cena", "ilosc", "gramatura", "opis", "opakowanie", "zdjecie", "nowosci"];

  // Przetwarzamy każdy obiekt produktu na tablicę
  const transformedData = api2Data.map(product => [
    product.nazwa,
    product.kategoria.trim(), // usuwamy dodatkowe spacje
    product.cena.toFixed(2),  // formatujemy cenę do dwóch miejsc po przecinku
    product.ilosc,
    product.gramatura,
    product.opis,
    product.opakowanie.trim(),
    product.zdjecie,
    product.nowosci ? 1 : 0,
    product.promocja,
    product.cena_30 
  ]);

  console.log(transformedData)

  // Zwracamy pełne dane, łącząc nagłówek z przetworzonymi danymi
  return [...transformedData];
};

// Używamy funkcji na danych z API2
products2 = transformData(products2);
products2.unshift(['','O nas']);
products2.unshift(['','Nowości']);
    

let basket = [];

export default function App() {
  const [productList, setProductList] = useState(products2);
  const location = useLocation();
  let divisions = new Set(_.map(productList, (data) => {return data[1]}));
  divisions = Array.from(divisions);

  const divisionsData = _.map(divisions, (division) => _.filter(productList, (product) => {return product[1] === division}));
  const [currentView, setCurrentView] = useState(divisions[0]);

  useEffect(() => {
    if (location.pathname === '/' && currentView != 'Nowości') {
        const el = document.getElementById('Nowości');
        el.click();
    }
    else if (location.pathname === '/' && currentView == 'Nowości'){
      const el = document.getElementById('herbatki ziołowe');
      el.click();
      setTimeout(() => {
        const el = document.getElementById('Nowości');
      el.click();
      }, 0);
    }
  }, [location.pathname]);

  const sliderData = _.filter(productList, (data) => {return data[8] === 1})
  const sliderPhotos = _.map(sliderData, (data) => {return './img/' + data[7] + '.jpg'});
  const sliderTitles = _.map(sliderData, (data) => data[0]);
  const sliderCategory = _.map(sliderData, (data) => data[1]);
  const sliderPrice = _.map(sliderData, (data) => data[2]);
  const sliderQty= _.map(sliderData, (data) => data[3]);

  const changeView = (view) => {
    setCurrentView(view);
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scroll smooth
    });
  }

  const PhotoSlider = ({ images, titles, views, prices, quantities }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const nextSlide = () => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    };
  
    const prevSlide = () => {
      setCurrentImageIndex(
        currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1
      );
    };
  
    return (
      <div className="photo-slider" style={{ position: 'relative', backgroundColor: '#f7f0ef', borderRadius: '20px', boxShadow: '0 1px 6px rgba(138, 134, 134, 0.8)'}}>
        <button
          onClick={prevSlide}
          className= {'sliderLeft'}
        >
          {'<'}
        </button>
        <div className = {'sliderContainer'}>
          <div className = {'sliderPhotoContainer'}>
            <img src={images[currentImageIndex]} alt={''} style={{borderRadius: '15px'}}/>
          </div>
          <div className = {'sliderSubContainer'}>
            <span className = {'sliderCategory'}>Kategoria: {views[currentImageIndex]}</span>
            {titles[currentImageIndex].replaceAll('"','')}
            <span className = {'sliderPrice'}>{prices[currentImageIndex]} zł</span>
            <span className = {'sliderQty'}>Pozostało: {quantities[currentImageIndex]} szt.</span>
            <button className = {'sliderButton'} onClick = {() => {changeView(views[currentImageIndex])}}>Przejdź do produktu</button>
          </div>
        </div>
        <button
          onClick={nextSlide}
          className = {'sliderRight'}
        >
          {'>'}
        </button>
      </div>
    );
  };

  const CardFlip = ({data}) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleFlip = () => {
      if(!isAnimating) {
        setIsFlipped(!isFlipped);
        setIsAnimating(true);
      }
    }

    const photo = 
      data[7].length > 0 ? 
        data[7]
        :
        'noImage';

    const getAllIndexes = (arr, val) => {
      var indexes = [], i = -1;
      while ((i = arr.indexOf(val, i+1)) != -1){
          indexes.push(i);
      }
      return indexes;
    }

   const description = data[5].split(';');
  
    return (
      <div className = {'cardContainer'}>
          <div className = {'flipCard'} onClick={handleFlip}>
            <motion.div
            className = {'flipCardInner'}
            initial = {false}
            animate = {{rotateY: isFlipped? 180:360}}
            transition = {{duration: 0.6, animationDirection: 'normal'}}
            onAnimationComplete = {() => {setIsAnimating(false)}}
            >
              <div className = {'flipCardFront'}>
                <img async src = {'./img/' + photo + '.jpg'} alt = ''/>
                <img className = {'noImage'} async src = {'./img/noImage.jpg'}/>
                <span className = {'productName'}>{data[0]}<span className = {'productWeight'}>{data[4]}</span></span>
                {data[9] != undefined ? <span className = {'discount'}><span className = {'discountPrice'}>{data[9].toFixed(2) + ' zł'}</span></span> : <></>}
                <span className = {'productPrice'}>{data[2] + ' zł'}</span>
                <span className = {'productQty'}>{'Pozostało: ' + (data[3] < 0 ? 0: data[3]) + ' szt.'}</span>
                {data[10] != undefined ? <span className = {'productPrice30'}>Ostatnie 30 dni: {data[10].toFixed(2) + ' zł'}</span> : <></>}
              </div>
              <div className = {'flipCardBack'}>{description.map(section => {
                return (
                  <React.Fragment>
                    <span className = {'descSection'}>{section}</span>
                  </React.Fragment>
                )
              })}</div>
            </motion.div>
          </div>
      </div>
    )
  }

  useEffect(() => {
    const element = document.getElementById(currentView);
    const divisionElem = document.getElementById('dV_' + currentView);
    if (element && divisionElem) {
      element.style.backgroundColor = '#F8EDEB';
      element.style.borderBottom = 'none';
      divisionElem.style.display = 'flex';
    }
  }, [currentView])
    

  const DividedView = () => {

    const [basketList, setBasketList] = useState(basket);

    const addToBasket = (product) => {
      alert('Dodano ' + '"' +  product.slice(0, product.indexOf('/')-1) + '"' + ' do koszyka')
      let tempBasket = new Set(basket);
      console.log(product + ' x1')
      tempBasket.add(product + ' x1');
      tempBasket = Array.from(tempBasket);
      basket = tempBasket; // Zaktualizowanie zmiennej `basket` bezpośrednio
      console.log(basket)
      setBasketList(tempBasket); // Uaktualnienie stanu lokalnego
      setBasketValue(basket)
    }

    const Basket = () => {
      return (
        <div className = {'basketContainer'}>
            <Link to='/basket' state = {{value:getBasketValue()}}><FaShoppingBasket/></Link> 
        </div>
      )
    }

    const Footer = () => {
      return (
        <div className = {'footerContainer'}>
          <div className = {'leftFooter'}>
            <a href = './docs/Regulamin.pdf'>Regulamin</a>
            <a href = './docs/PolitykaPrywatnosci.pdf'>Polityka prywatności</a>
          </div>
          <div className = {'rightFooter'}>
            <a href = './docs/Wysylka.pdf'>Wysyłka</a>
            <a href = './docs/OdstapienieOdUmowy.pdf'>Odstąpienie od umowy</a>
          </div>
        </div>
      )
    }

    const quote = 'Przyjmij tempo Natury: jej sekretem jest cierpliwość';

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    return (
      <div className={`dividedContainer ${showMenu ? 'show' : ''}`}>
        <div>
          <Basket/>
        </div>
        <div className={`menuContainer ${showMenu ? 'show' : ''}`}>
          <div className={`menuIcon ${showMenu ? 'show' : ''}`} onClick={toggleMenu}>
          ☰
          </div>
          <React.Fragment>
            { _.map(divisions, (divisionName) => {
              return (
                <>
                  <div className={`menuLabel ${showMenu ? 'show' : ''}`} key = {divisionName} id = {divisionName} onClick = {() => changeView(divisionName)}><span>{divisionName.charAt(0).toUpperCase() + divisionName.slice(1)}</span></div>
                  <div className = {'divisionView'} id = {'dV_' + divisionName}>
                    {divisionName === 'Nowości' || divisionName === 'O nas' ?
                    <>
                    {divisionName === 'Nowości' ?
                    <div className={`startPage ${showMenu ? 'show' : ''}`}>
                      <div className = {'quoteContainer'}>
                        <q>{quote}</q>
                      </div>
                      <div className = {'startPagePhotosContainer'}>
                        <div className = {'photoLeft'}>
                          <img src = './img/left.jpg'/>
                          <div>
                            <h3>W zgodzie z Naturą</h3>
                            <span>Kierując się zasadami sezonowości i poszanowania dla świata roślin</span>
                          </div>
                        </div>
                        <div className = {'photoRight'}>
                          <img src = './img/right.jpg'/>
                          <div>
                            <h3>Z upraw ekologicznych</h3>
                            <span>Dbając o dobro człowieka i Matki Ziemi</span>
                          </div>
                        </div>
                      </div>
                      <div className = {'nowosci'}><h1>Nowości</h1></div>
                      <div className = {'slider'}>
                        <PhotoSlider images ={sliderPhotos} titles = {sliderTitles} views = {sliderCategory} prices = {sliderPrice} quantities = {sliderQty}/>
                      </div>
                    </div>
                      :
                      <>
                      <div className={`about ${showMenu ? 'show' : ''}`}>
                        <h1>O nas</h1>
                        <img src = './img/oNas.jpg'/>
                        <span>Mama i Córka. Agnieszka i Patrycja. Jesteśmy kobietami, które połączyły się z Naturą - dzielimy się nią poprzez skarby, które tworzymy. <br/><br/>

                        Naturianki to sklepik działający w ramach RHD (Rolniczego Handlu Detalicznego). Nie jesteśmy, jak typowa firma - prowadzimy EKOLOGICZNE gospodarstwo rolne, które jest w czasie konwersji (trzyletniego okresu przestawiania) pod nadzorem jednostki certyfikującej. Pielęgnujemy rośliny z miłością, szacunkiem do Ziemi, jednocześnie kierując się zasadami permakultury.  <br/><br/>U nas w domu od zawsze królowały na stole kiszonki, domowe przetwory i soki. Od czasów pandemii zakochałyśmy się także w ziołach, które możesz znaleźć w naszych ziołowych herbatach (ręcznie zbieranych i przetwarzanych). <br/><br/>Pasja ta sprawiła, że obie zaczęłyśmy się kształcić w kierunku ziołolecznictwa - Agnieszka u dr Różańskiego, Patrycja zaś w Instytucie Zielarstwa Polskiego i na Uniwersytecie Medycznym w Poznaniu. 

                  <br/><br/>Pragniemy dzielić się z Tobą tym, co mamy najcenniejsze, czyli wdzięcznością i skarbami, które daje nam Natura. Rozgość się tutaj i zakochaj się w Mamie Ziemi razem z nami. Czas na nowo powrócić do korzeni... 

                  A&P</span>
                      <span class = 'contact'>
                        Kontakt: sklep.naturianki@gmail.com <br/>
                        Instagram: @naturianki
                      </span>
                      </div>
                      </>
                    }
                    </>
                    
                    :
                    <div className={`divisions ${showMenu ? 'show' : ''}`}>
                      {_.filter(productList.filter(product => {return product[1] != 'Nowości' && product[1] != 'O nas'}), (product) => {return product[1] === divisionName}).map((product, idx) => {
                        return (
                          <>
                            <CardFlip data = {product}/>
                            <button className = {product[3] > 0 ? 'button' : 'buttonDisabled'} onClick ={() => {if(product[3] > 0) {addToBasket(product[0] + ' - ' + product[6] + ' /' + product[2] + ' zł')}}}>{product[3] > 0 ? 'Do Koszyka' : 'Brak produktu'}</button>
                          </>
                        )
                      })}
                    </div>
                    }
                    <Footer/>
                  </div>
                </>
               )
            })}
          </React.Fragment>
          
        </div>
      </div>
    )
  }

  return (
    <div className="App">
      <Routes>
        <Route path = '/' element = {
          <div>
            <div className = {'mainView'}>
                <div className = {'header'}>
                  <img src = {'./img/logo.png'} onClick = {() => changeView('Nowości')}/>
                </div>
                <DividedView/>
            </div>
          </div>
        }/>
        <Route path = '/basket' element = {<BasketView/>}/>
      </Routes>
      
    </div>
  );
}
